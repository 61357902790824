<template>
  <a-layout>
<!--    <a-layout-header style="background: #fff; padding: 0"/>-->
    <a-layout-content style="margin: 16px;">
<!--      <a-breadcrumb style="margin: 16px 0">-->
<!--        <a-breadcrumb-item>首页</a-breadcrumb-item>-->

<!--      </a-breadcrumb>-->
      <div>
<!--        <h1>待审核</h1>-->
<!--        {{countdata}}-->
        <a-row :gutter="16" v-if="countdata">
          <a-col :span="6">
            <router-link to="/order_list?type=1">
            <a-card :bordered="false">

              <a-statistic :value="countdata['1']['3']" title="网站VIP"/>

            </a-card>
            </router-link>
          </a-col>
          <a-col :span="6">
            <router-link to="/order_list?type=2">
            <a-card :bordered="false">
              <a-statistic :value="countdata['2']['3']" title="购买独立文章\视频"/>
            </a-card>
            </router-link>
          </a-col>
          <a-col :span="6">
            <router-link to="/order_list?type=3">
            <a-card :bordered="false">
              <a-statistic :value="countdata['3']['3']" title="老师考试"/>
            </a-card>
            </router-link>
          </a-col>
          <a-col :span="6">
            <router-link to="/order_list?type=4">
            <a-card :bordered="false">
              <a-statistic :value="countdata['4']['3']" title="课程报名"/>
            </a-card>
            </router-link>
          </a-col>
        </a-row>
<!--        {{ countdata }}-->
      </div>
      <div style="background: #fff; padding: 30px;margin-top:16px;">
        {{ this.uid }}<br/>
        {{ this.token }}<br/>

      </div>
    </a-layout-content>
    <a-layout-footer style="text-align: center">

    </a-layout-footer>

  </a-layout>
</template>

<script>

// import { Modal } from "ant-design-vue";
// import Storage from "../common/storage";

import Storage from "../common/storage";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      countdata: [],
      uid: Storage.uid,
      token: Storage.token,
    };
  },
  created() {
    console.log(Storage.token_timeout)
    console.log(Storage.token)
    let now = Date.now() / 1000;
    if(Storage.token_timeout > now && Storage.token !== "" ){
      console.log("now: ", now);
      this.getcount();
    }else{
      Storage.token = "";
      window.vue.$router.push({path: "/login"});

    }
    // if(Storage.token_timeout > moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')){

  },
  methods: {
    async getcount() {
      this.loading = true;
      this.countdata = [];
      console.log(Storage.uid)
      console.log(Storage.token)
      let now = Date.now();
      let result = await this.$get("/api/admin/order/count", {
        params: {
          token: Storage.token,
          uid: Storage.uid,
          t: now,
        }
      });

      if (result.status === true) {

        this.countdata = result.data;
        this.loading = false;
      }

      this.loading = false;
    },
  }
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.serach_input {
  width: 25vw;
  float: right;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: right;
  margin-right: 25px;
  z-index: 1000;
}

.pages {
  float: right;
  margin-top: 10px;
}

.toolsbar {
  display: block;
  width: 100%;
  height: 42px;
  background: #fff;
}
</style>
